import http from "axios";
import helpers from './helpers';
import appStore from "@/store/App.store";
import {cdiVars} from "@/cdiVars";

export const store = {
    post: async function(url, qsPairs){
        appStore.loadingMessage();
        let qsString = helpers.getQSString(qsPairs);
        return await http.post(url, qsString, { headers: helpers.authHeader() })
            .then(res => this.handleResponse(res.data))
            .catch();
    },

    handleResponse: function(data) {
        if (data.opt == 'denied') {
            appStore.autoSignOut();
        }else if (data.opt == 'error'){
            appStore.errorBox(data.msg);
        }else{
            appStore.successBox(data.msg);
            return data;
        }
    },

    api: function(endPoint, options) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_URL}${endPoint}`, options))
        );
    },
    reportsApi: function(endPoint, options) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_REPORT_URL}${endPoint}`, options))
        );
    },
    
    search: function(endPoint, options) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_REPORT_URL}/${endPoint}`, options))
        );
    },
}