export default {
    CDI_LOCATIONS: [
        [3, 'Charlotte'],
        [4, 'Dallas'],
        [1, 'Minneapolis'],
        [5, 'Phoenix'],
        [2, 'Seattle']
    ],
    CDI_LOCATIONS_KV: {
        3: 'Charlotte',
        4: 'Dallas',
        1: 'Minneapolis',
        5: 'Phoenix',
        2: 'Seattle'
    }
}