<template>
    <transition name="modal_fade">
        <div>
            <div class="modal" role="dialog" v-draggable aria-labelledby="modalTitle" aria-describedby="modalDescription">

                <div class="modal_header">
                    {{heading}}
                    <button type="button" class="modal_btn_close" @click="close(false)" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
                </div>

                <div class="modal_body">{{body}}</div>

                <div class="modal_footer">
                    <button type="button" class="btn btn-primary" @click="close(true)" aria-label="Close modal">Yes</button>
                    <button type="button" class="btn btn-default" @click="close(false)" aria-label="Close modal">No</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import draggable from "@/directives/draggable";

    export default {
        name: "ConfirmBox",
        data() {
            return {
            }
        },
        directives: {
            draggable
        },
        props: ['heading', 'body'],
        methods: {
            close: function (confirm) {
                this.$emit('close', confirm);
            }
        }
    }
</script>

<style scoped>
.modal{
    position: fixed;
}
</style>
