export default {
    STATES: [
        ['AL', "Alabama"],
        ['AK', "Alaska"],
        ['AB', "Alberta"],
        ['AZ', "Arizona"],
        ['AR', "Arkansas"],
        ['BC', "British Columbia"],
        ['CA', "California"],
        ['CO', "Colorado"],
        ['CT', "Connecticut"],
        ['DE', "Delaware"],
        ['DC', "District of Columbia"],
        ['FL', "Florida"],
        ['GA', "Georgia"],
        ['GU', 'Guam'],
        ['HI', "Hawaii"],
        ['ID', "Idaho"],
        ['IL', "Illinois"],
        ['IN', "Indiana"],
        ['IA', "Iowa"],
        ['KS', "Kansas"],
        ['KY', "Kentucky"],
        ['LA', "Louisiana"],
        ['ME', "Maine"],
        ['MB', "Manitoba"],
        ['MD', "Maryland"],
        ['MA', "Massachusetts"],
        ['MI', "Michigan"],
        ['MN', "Minnesota"],
        ['MS', "Mississippi"],
        ['MO', "Missouri"],
        ['MT', "Montana"],
        ['NE', "Nebraska"],
        ['NV', "Nevada"],
        ['NB', "New Brunswick"],
        ['NH', "New Hampshire"],
        ['NJ', "New Jersey"],
        ['NM', "New Mexico"],
        ['NY', "New York"],
        ['NL', "Newfoundland and Labrador"],
        ['NC', "North Carolina"],
        ['ND', "North Dakota"],
        ['NT', "Northwest Territories"],
        ['NS', "Nova Scotia"],
        ['NU', "Nunavut"],
        ['OH', "Ohio"],
        ['OK', "Oklahoma"],
        ['ON', "Ontario"],
        ['OR', "Oregon"],
        ['PA', "Pennsylvania"],
        ['PE', "Prince Edward Island"],
        ['PR', "Puerto Rico"],
        ['QC', "Quebec"],
        ['RI', "Rhode Island"],
        ['SK', "Saskatchewan"],
        ['SC', "South Carolina"],
        ['SD', "South Dakota"],
        ['TN', "Tennessee"],
        ['TX', "Texas"],
        ['UT', "Utah"],
        ['VT', "Vermont"],
        ['VI', "Virgin Islands"],
        ['VA', "Virginia"],
        ['WA', "Washington"],
        ['WV', "West Virginia"],
        ['WI', "Wisconsin"],
        ['WY', "Wyoming"],
        ['YT', "Yukon"]
    ]
}